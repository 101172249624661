<div style="position: relative">
  <i
    tabindex="0"
    role="button"
    [attr.aria-label]="'search instructions'"
    class="infobtn dpg-icon dpg-icon-system-alert-info"
    (click)="handleInfoClick($event)"
    (keydown.escape)="closeInfo($event)"
    (keydown.enter)="handleInfoClick($event)"
    (keydown.Space)="handleInfoClick($event)"
    [attr.aria-expanded]="isInfoVisible"
  >
  </i>
  <div
    class="info"
    [ngClass]="{ visible: isInfoVisible, 'left': isLeftAligned }"
    [innerHTML]="'contents.toc_info' | translate"
  ></div>
</div>